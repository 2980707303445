.option-container {
    border: 1px solid #768e32;
    background: #ffffff;
}

.option {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 53px 3fr 1fr;
    grid-template-rows: 2fr;
    padding: 12px;
}

.option-container.active > .option {
    background: #f5f7f0;
}

.components {
    padding: 16px 8px;
    border-top: 1px solid #768e32;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.option .name {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.option .icon {
    width: 53px;
    height: 53px;
    grid-row-end: span 2;
    cursor: pointer;
    overflow: hidden;
}

.option .icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.option .price {
    color: #4a4a4a;
    font-size: 13px;
    font-weight: 600;
    text-align: right;
}

.option .details {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    color: #170e0e;
    font-size: 12px;
    font-weight: 400;
}

.option .details.open svg {
    transform: rotate(180deg);
}

.option .value {
    color: #170e0e;
    font-size: 12px;
    font-weight: 400;
}

.option .description {
    color: #170e0e;
    font-size: 12px;
    font-weight: 400;
    grid-column-end: span 3;
}
