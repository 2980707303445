.simple-radio-buttons {
    display: flex;
    gap: 16px;
}

.simple-radio-buttons__option {
    display: flex;
    gap: 4px;
    align-items: center;
}

.simple-radio-buttons__label {
    color: #170e0e;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
}

.simple-radio-buttons__input {
    height: 20px;
    width: 20px;
    accent-color: #2fa69a;
}