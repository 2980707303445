.horizontal-switch {
    width: 100%;
    display: flex;
    align-items: center;
}

.horizontal-switch-item {
    flex-grow: 1;
    text-align: center;
    padding: 11px 0;
    cursor: pointer;
    color: #333333;
    font-size: 12px;
    border: 1px solid #cccccc;
}

.horizontal-switch-item.select {
    color: #170e0e;
    border: 1px solid #768e32;
    background: #f5f7f0;
}