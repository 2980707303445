.simple-number-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.simple-number-input__label span:first-child {
    color: #4a4a4a;
    font-size: 16px;
    line-height: 28px;
}

.simple-number-input__input {
    padding: 2px 0;
    outline: none;
    flex-grow: 1;
}

.simple-number-input__input input {
    -moz-appearance: textfield;
    border: none;
    outline: none;
    color: #4a4a4a;
    background-color: transparent;
    font-size: 14px;
    text-align: center;
    width: 30px;
}

.simple-number-input__input input::-webkit-outer-spin-button,
.simple-number-input__input input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.simple-number-input__minus, .simple-number-input__plus {
    width: 25px;
    height: 25px;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.1s;
    cursor: pointer;
    font-size: 18px;
}

.simple-number-input__minus, .simple-number-input__plus {
    border: 1px solid #727272;
    background-color: #ffffff;
    color: #4a4a4a;
}

.simple-number-input__minus:active, .simple-number-input__plus:active, .simple-number-input__plus:active, .simple-number-input__minus:active {
    transform: scale(0.95);
}