.text-input {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.text-input label {
    font-size: 14px;
    color: #bcc1c7;
}

.text-input input {
    color: #333333;
    font-size: 14px;
    font-weight: 600;
    border-radius: 2px;
    border: 1px solid #bcc1c7;
    padding: 10px 5px;
}

.text-input input:focus {
    outline: none;
    border: 1px solid #474e4b;
}