#configurator-scene {
    width: calc(100% - 488px);
    height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    position: absolute;
    top: 0;
    left: 0;
    right: 468px;
}

.map > canvas {
    display: none !important;
}

.map > svg {
    display: block !important;
}

#configurator-scene > svg, #configurator-scene > canvas {
    z-index: 1;
}

#configurator-scene > svg {
    width: 100%;
    max-width: 700px;
    max-height: 100%;
    object-fit: cover;
}

.full-screen #configurator-scene {
    margin-top: 0;
    height: 100vh;
    width: 100%;
    order: 1;
}

#canvas-loader {
    display: none;
    position: absolute;
    inset: 0;
    background-color: rgba(71, 78, 75, 0.2);
    z-index: 2;
}

@media (min-width: 1280px) {
    #configurator-scene {
        margin-right: auto;
    }
}

@media (max-width: 768px) {
    #configurator-scene {
        position: relative;
        min-height: 300px;
        width: 100%;
        min-width: 100%;
    }
}