* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    line-height: normal;
    text-rendering: optimizeLegibility;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    margin: 0;
    padding: 0;
    background-color: #ffffff;
}

/* Width container full width */
#content-main > .container:nth-child(2) {
    max-width: 100% !important;
}

.logo-container {
    max-width: 340px;
}

.logo-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.configurator-place {
    margin: 0 auto;
    align-items: start;
    position: relative;
}

.configurator-ui-wrapper {
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    display: grid;
    grid-template-columns: minmax(0, 1fr) 488px;
    grid-template-rows: 1fr 100fr;
    gap: 0;
}

.configurator-ui-wrapper > div:first-child {
    grid-row-start: auto;
    grid-row-end: span 2;
}

.full-screen.configurator-place {
    grid-template-columns: 1fr;
}

@media (max-width: 768px) {
    .configurator-place {
        margin: 0 auto 0 auto;
    }

    .configurator-ui-wrapper {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        height: fit-content;
    }
}
