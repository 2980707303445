.data-table table {
    width: 100%;
    border-collapse: collapse;
}

.data-table tr td {
    padding: 6px 0;
    border-bottom: 1px solid #3C3C4399;
}

.data-table tr td:nth-child(1) {
    color: #170E0E;
    font-size: 14px;
}

.data-table tr td:nth-child(2) {
    color: rgba(60, 60, 67, 0.60);
    font-size: 12px;
    width: 40px;
    padding-left: 8px;
}

.data-table tr td:nth-child(3) {
    color: rgba(60, 60, 67, 0.60);
    font-size: 12px;
    text-align: right;
    padding-left: 8px;
    width: 55px;
}

.data-table tr td:nth-child(4) {
    color: rgba(60, 60, 67, 0.60);
    font-size: 12px;
    text-align: right;
    padding-left: 8px;
    width: 80px;
}

.data-table tr td:nth-child(5) {
    color: #170E0E;
    font-size: 12px;
    text-align: right;
    padding-left: 8px;
    width: 80px;
}

.data-table .total-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0;
}

.data-table .total-price span:first-child {
    color: #170E0E;
    font-size: 18px;
    font-weight: 700;
}

.data-table .total-price span:last-child {
    color: #170E0E;
    font-size: 18px;
    font-weight: 400;
}