.number-input {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.number-input__label {
    display: flex;
    flex-direction: column;
}

.number-input__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2px;
}

.number-input__label span:first-child {
    color: #4a4a4a;
    font-size: 16px;
    line-height: 28px;
}

.number-input__label span:last-child {
    color: #b8b8b8;
    font-size: 12px;
    line-height: 28px;
}

.number-input__unit {
    color: #4a4a4a;
    font-size: 14px;
    margin: 0 4px;
}

.number-input__input {
    border: 1px solid #D5D5D5;
    padding: 2px 0;
    outline: none;
    flex-grow: 1;
}

.number-input__input input {
    -moz-appearance: textfield;
    border: none;
    outline: none;
    color: #4a4a4a;
    font-size: 14px;
    text-align: right;
    width: 50px;
}

.number-input__input input::-webkit-outer-spin-button,
.number-input__input input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.number-input__minus, .number-input__plus, .number-input__minus--sub, .number-input__plus--sub {
    width: 25px;
    height: 25px;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.1s;
    cursor: pointer;
    font-size: 18px;
}

.number-input__minus--sub, .number-input__plus--sub {
    width: 20px;
    height: 20px;
    font-size: 12px;
}

.number-input__minus, .number-input__minus--sub {
    border: 1px solid #768E32;
    background-color: #ffffff;
    color: #4a4a4a;
}

.number-input__plus, .number-input__plus--sub {
    border: 1px solid #768E32;
    background-color: #768E32;
    color: #ffffff;
}

.number-input__minus:active, .number-input__plus:active, .number-input__minus--sub:active, .number-input__plus:active, .number-input__minus:active {
    transform: scale(0.95);
}