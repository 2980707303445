.configurator-total-price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
}

.configurator-total-price span:first-child {
    color: #000000;
    font-family: "Roboto Slab", "Roboto", sans-serif;
    font-size: 24px;
    font-weight: 500;
}

.configurator-total-price span:last-child {
    color: #c5c5c5;
    font-family: "Roboto Slab", "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
}