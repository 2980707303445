#configurator-ui {
    grid-row-start: auto;
    grid-row-end: span 2;
    background-color: #ffffff;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.full-screen #configurator-ui {
    display: none;
}

.save-button {
    width: 100%;
    border-radius: 100px;
    min-height: 50px;
    background: #c48843;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 1px solid transparent;
}

.save-button:active {
    transform: scale(0.95);
}

@media (max-width: 768px) {
    #configurator-ui {
        grid-row-end: span 1;
    }
}

@media (min-width: 769px) {
    #configurator-ui {
        padding: 20px;
    }
}


