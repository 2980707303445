.wand-selector {
    display: inline-block;
    transform: scaleY(-1);
}

.wand-selector .row {
    display: flex;
    flex-wrap: nowrap;
}

.wand-selector .row > .beam {
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
    background-color: #4a4a4a;
}

.wand-selector .row > .wall {
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.wand-selector .row > .wall:hover {
    background-color: #bfca9f;
}

.wand-selector .row > .h-wall {
    width: 160px;
    height: 20px;
    border-top: 1px solid #ababab;
    border-bottom: 1px solid #ababab;
}

.wand-selector .row > .v-wall {
    width: 20px;
    height: 160px;
    border-left: 1px solid #ababab;
    border-right: 1px solid #ababab;
}

.space {
    width: 160px;
    height: 160px;
}

.wand-selector .row > .wall.active {
    background-color: #bfca9f;
}

.wand-selector .row > .wall.selected {
    background-color: #768e32;
}


