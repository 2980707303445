.result-popup {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.result-popup > div {
    width: 100%;
    max-width: 500px;
    background-color: #ffffff;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.result-popup button {
    width: 100%;
    border-radius: 100px;
    height: 50px;
    background: #c48843;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 1px solid transparent;
}