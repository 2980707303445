.left-bar {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    z-index: 3;
    left: 20px;
    top: 0;
    bottom: 0;
}

.left-bar-button {
    width: 50px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.left-bar-button svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.left-bar span {
    gap: 5px;
    align-items: center;
    font-size: 10px;
}

.toggle-2d-button, .toggle-3d-button {
    border: none;
    cursor: pointer;
}

.toggle-2d-button svg, .toggle-3d-button svg {
    min-width: 80px;
    min-height: 80px;
    fill: #343434;
}

.toggle-2d-button.active svg, .toggle-3d-button.active svg {
    background-color: #768e32;
    fill: #ffffff;
}

.toggle-2d-button {
    margin-bottom: 30px;
}

.toggle-legend {
    border: none;
    cursor: pointer;
    color: #474e4b;
    font-weight: bold;
    font-size: 30px;
    font-family: 'Playfair Display', serif;
}

.toggle-legend:hover {
    background-color: #768e32;
    color: #ffffff;
}

.toggle-2d-button:active, .toggle-3d-button:active, .toggle-legend:active {
    transform: scale(0.95);
}

.full-screen .left-bar {
    display: none;
}

.map #move-btn-info, .map #zoom-btn-info, .map #rotate-btn-info {
    visibility: hidden;
}

@media (max-width: 768px) {
    .left-bar {
        top: 20px;
        bottom: 20px;
        left: 0;
        justify-content: space-between;
    }

    #rotate-btn-info, #zoom-btn-info, #move-btn-info {
        display: none;
    }
}