.radio-blocks {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.radio-blocks-label {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    text-align: left;
}

.radio-block {
    border: 1px solid #d0d0d0;
    gap: 16px;
    cursor: pointer;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 53px 3fr 1fr;
    grid-template-rows: 2fr;
    padding: 12px;
}

.radio-block.selected {
    border: 1px solid #768e32;
    background-color: #f5f7f0;
    position: relative;
}

.radio-block.disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.radio-block > .icon {
    width: 56px;
    height: 56px;
    grid-row-end: span 2;
}

.radio-block.in_value > .icon {
    grid-row-end: span 1;
}

.radio-block > .icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.radio-block > .name {
    color: #170e0e;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    grid-row-end: span 2;
}

.radio-block.in__value > .name {
    grid-row-end: span 1;
}

.radio-block > .price {
    color: #4a4a4a;
    font-size: 13px;
    font-weight: 600;
    text-align: right;
}

.radio-block > .value {
    grid-column-end: span 2;
    display: flex;
    align-items: center;
}

.radio-block > .details {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    color: #170e0e;
    font-size: 12px;
    font-weight: 400;
}

.radio-block > .description {
    color: #170e0e;
    font-size: 12px;
    font-weight: 400;
    grid-column-end: span 3;
}

.radio-block > .component {
    display: flex;
    grid-column-end: span 3;
}

.color-radio-blocks {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
}

.color-radio-blocks > .radio-block {
    flex-basis: calc(50% - 4px);
    height: 48px;
    gap: 0;
}

.color-radio-blocks > .radio-block > .icon {
    width: 48px;
    height: 48px;
    border-right: 1px solid #d0d0d0;
    overflow: hidden;
}

.color-radio-blocks > .radio-block > .name {
    text-align: center;
}

.big-radio-blocks .radio-block {
    height: 72px;
    gap: 12px;
}

.big-radio-blocks .radio-block > .icon {
    width: 72px;
    height: 72px;
    overflow: hidden;
}