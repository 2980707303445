.tabs-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow-y: auto;
}

.tab.hidden {
    display: none;
}

.tab-header {
    background-color: #f3f3f3;
    text-align: left;
    padding: 16px 32px 16px 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.tab-header span {
    color: #000000;
    font-size: 20px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 16px;
}

.tab-header svg {
    fill: #34485c;
}

.tab-header > svg {
    transition: transform ease-in .2s;
}

.tab-header.open {
    background-color: #768e32;
}

.tab-header.open span {
    color: #ffffff;
}

.tab-header.open svg {
    transform: rotate(-180deg);
    fill: #ffffff;
}

.tab-content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
    transition: opacity 0.3s ease-out, max-height 0.3s ease-out, visibility 0.3s ease-out;
}

.tab-content > div:first-child {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px 20px;
}

.tab-header.open + .tab-content {
    opacity: 1;
    max-height: 3000px;
    visibility: visible;
    transition: none;
}

.tab-header > span > svg {
    margin-top: 2px;
}

.tab-header > span > span {
    color: #768e32;
    font-weight: 700;
}

.order-button {
    border-radius: 6px;
    background-color: #474e4b;
    color: #ffffff;
    box-shadow: 0px -5px 11.8px 0px rgba(0, 0, 0, 0.15);
    padding: 16px 24px 16px 36px;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
    width: 100%;
    cursor: pointer;
}

.full-screen .tabs-container {
    display: none;
}

.tab-content .tab-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 24px 20px;
}

.tab-content .tab-navigation button {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    height: 48px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
}

.tab-content .tab-navigation button:active {
    transform: scale(0.95);
}

.tab-content .tab-navigation .prevButton {
    background: #bfca9f;
    width: 56px;
}

.tab-content .tab-navigation .nextButton {
    background: #768e32;
    width: 138px;
    margin-left: auto;
}