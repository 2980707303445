.walls-selector-container {
    width: 100%;
    max-width: 468px;
    overflow-x: auto;
    text-align: center;
}

.walls-tab .horizontal-switch-item {
    color: #717171;
    font-size: 16px;
    font-weight: 500;
    border: none;
    border-bottom: 1px solid #d6d6d6;
}

.walls-tab .horizontal-switch-item.select {
    border: none;
    background-color: #ffffff;
    border-bottom: 1px solid #768e32;
}

.walls-tab .horizontal-tab-header {
    color: #717171;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid #d6d6d6;
}