.OptionBox {
    display: flex;
    z-index: 3;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 16px;
    align-self: start;
    margin: 0 auto 20px auto;
}

.optionsBoxBlock {
    display: grid;
    grid-template-columns: repeat(2, minmax(40px, 1fr));
    row-gap: 6px;
    flex-basis: 25%;
    flex-grow: 2;
}

.optionsBoxBlock:last-child {
    grid-template-columns: repeat(2, minmax(60px, 1fr));
}

.optionBoxSeparator {
    width: 1px;
    background-color: #cccccc;
    margin: 0 24px;
}

.optionsBoxHeader {
    color: #170e0e;
    font-size: 14px;
    font-weight: 500;
}

.optionBoxBullets {
    display: flex;
    gap: 4px;
    justify-content: flex-end;
    align-items: center;
}

.optionBoxBullet {
    width: 6px;
    height: 6px;
    background: #170e0e;
}

.optionsBoxTitle, .optionsBoxValue {
    color: #6c6c6c;
    font-size: 13px;
    font-weight: 400;
}

.optionsBoxValue {
    text-align: right;
}

.full-screen .OptionBox {
    display: none !important;
}

.OptionBox.hidden {
    visibility: hidden;
}

@media (max-width: 768px) {
    .OptionBox.hidden {
        display: none;
    }

    .optionsBoxBlock {
        grid-template-columns: repeat(2, minmax(40px, 1fr));
    }

    .optionBoxSeparator {
        margin: 0 5px;
    }
}