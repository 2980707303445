.horizontal-tabs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.horizontal-tabs {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    overflow-x: auto;
}

.horizontal-tab-header {
    flex-grow: 1;
    text-align: center;
    padding: 11px 0;
    cursor: pointer;
    color: #717171;
    font-size: 16px;
    font-weight: 500;
}

.horizontal-tab-content {
    display: none;
}

.horizontal-tab-content.active {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.horizontal-tab-header.open {
    color: #000000;
    border-bottom: 1px solid #768e32;
}