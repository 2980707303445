.save-offer-form {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.save-offer-form form, .save-offer-form > div {
    width: 100%;
    max-width: 500px;
    background-color: #ffffff;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.save-offer-form .save-offer-form-field {
    flex-basis: calc(50% - 10px);
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.save-offer-form .error-msg {
    color: #ff0000;
    font-size: 12px;
}

.save-offer-form button {
    width: 100%;
    border-radius: 100px;
    height: 50px;
    background: #c48843;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 1px solid transparent;
}